<template>
    <div>
        <VendorTable :location="75"></VendorTable>
    </div>
</template>
<script>
import VendorTable from "./vendorTable.vue"
export default {
    components: {
        VendorTable
    }
}
</script>